<template>
    <div>
        <md-dialog
            :md-active.sync="showDialog"
            :md-fullscreen="false"
            class="popup-dialog"
        >
            <update-reward
                :id="reward_id"
                :member_id="0"
                templateMode="true"
                :group="activeTab"
                @close="showDialog=false"
            />
        </md-dialog>

        <div class="manage-rewards">
            <md-tabs class="md-transparent admin-groups-tabs" md-alignment="left">
                <template v-for="(group, alias) in $store.getters['reward/groups']">
                    <md-tab
                        :key="alias"
                        :id="alias"
                        :md-icon="require(`@/assets/images/categories/reward/${group.icon}.svg`)"
                        @click="activeTab = alias"
                    ></md-tab>
                </template>
            </md-tabs>

            <md-table v-model="list">
                <md-table-toolbar>
                    <h1 class="md-title">Rewards</h1>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="Image" md-sort-by="id">
                        <img :src="item.icon.indexOf('base_') + 1 ? $baseIconUrl(item.icon) : $uploadedImageUrl(item.icon)" alt="" width="50" height="50">
                    </md-table-cell>
                    <md-table-cell md-label="Title (Alias)" md-sort-by="id"><a href="#" @click.prevent="update(item)">{{$t(item.title)}}</a></md-table-cell>
                    <md-table-cell md-label="Reward" md-sort-by="gender">{{ item.groups }}</md-table-cell>
                    <md-table-cell md-label="Gender" md-sort-by="gender">{{ item.gender }}</md-table-cell>
                    <md-table-cell md-label="Ages" md-sort-by="ages">{{ getAges(item.ages) }}</md-table-cell>
                    <md-table-cell md-label="Automatic addition" md-sort-by="ages">{{ item.is_auto_addition ? 'Yes' : 'No' }}</md-table-cell>
                    <md-table-cell md-label="Action">
                        <md-button @click="deleteReward(item)">
                            <md-icon>delete_outline</md-icon>
                        </md-button>
                    </md-table-cell>
                </md-table-row>
            </md-table>

            <md-button class="md-fab md-primary md-fab-bottom-right" @click="add">
                <md-icon>add</md-icon>
            </md-button>

        </div>
    </div>

</template>


<script>

    import UpdateReward from "../Reward/UpdateReward";

    export default {

        // Components list
        components: {UpdateReward},

        // Data
        data() {
            // Current tab
            return {
                activeTab: false,
                showDialog: false,
                woDialog: false,
                reward_id: 0,
                member_id: this.$route.params.member_id
            };
        },

        // Computed
        computed:
            {
                // get rewards list
                list() {
                    // Get list
                    return this.$get("reward/templateList", this.activeTab).sort((a, b) => {
                        if(a.order === null){
                            return 1;
                        }
                        else if(b.order === null){
                            return -1;
                        }
                        else if(a.order === b.order){
                            return 0;
                        }
                        else {
                            return a.order < b.order ? -1 : 1;
                        }
                    });
                },

                // Member
                member() {
                    return this.$get("member/get", this.member_id);
                }
            },

        // Mounted
        mounted() {
            // Loading tasks
            this.$dispatch("reward/loadTemplates");

            // Set app params
            this.$dispatch("misc/setTitle", this.$t("ttlMemberRewards"));

            // Get groups
            let grps;
            if ((grps = this.$get('reward/groups'))) {
                this.activeTab = Object.keys(grps)[0];
            }
        },

        // Methods
        methods:
            {
                getAges(ages) {

                    let a = ''
                    ages.map(age => {
                        if (window.app.$yearsTable[age]) {
                            a += window.app.$yearsTable[age] + ', '
                        }
                    })
                    return a
                },
                /**
                 * Add new reward
                 */
                add() {
                    // Show dialog
                    this.reward_id = 0;
                    this.showDialog = true;
                },

                /**
                 * Update member
                 * @param reward
                 */
                update(reward) {
                    // Go to update page
                    //this.$router.push({name: 'update-reward', params: {reward_id: reward.id}});
                    this.reward_id = reward.id;
                    this.showDialog = true;
                },

                /**
                 * Update member
                 * @param reward
                 */
                writeOff(reward) {
                    // Go to update page
                    //this.$router.push({name: 'update-reward', params: {reward_id: reward.id}});
                    this.reward_id = reward.id;
                    this.woDialog = true;
                },

                /**
                 * Delete reward
                 */
                deleteReward(reward) {
                    // Confirm deletion
                    if (!confirm(this.$t("qDelete") + " " + reward.title)) return;

                    // Add reward to the family
                    this.$dispatch("reward/delete", {id: reward.id});
                }
            }
    }
</script>

<style lang="scss">
    @import './src/sass/groups.scss';

    .manage-rewards {
        padding-bottom: 80px;

        .md-tab-nav-button {
            width: 60px;
            min-width: auto;
        }
    }
</style>

