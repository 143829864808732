<template>
    <div class="md-modal">
        <h1 class="reward__title">{{isUpdateMode ? $t('btSave') : $t('btAdd')}}</h1>
        <ap-form
            :fields="fields"
            :values="reward"
            :buttons="buttons"
            @change="save"
            @close="$emit('close')"
        ></ap-form>
    </div>
</template>

<script>

    import ApForm from "../../components/ApForm"

    export default {

        // Components list
        components: {ApForm},

        // Properties
        props: ["id", "group", "member_id", "templateMode"],

        // Form dadta
        data: () => ({}),

        computed:
            {
                // Form mode
                isUpdateMode() {
                    // Return mode
                    return this.id ? 1 : 0;
                },

                // Get family
                family() {

                    // Get family
                    return this.$get("family/get");
                },

                // Load reward
                reward() {

                    // rewards values
                    let rew = {};

                    // check for parameter
                    if (this.id) {
                        // Get object
                        rew = this.$unreactGet('reward/get', this.id);

                        // Unpack groups
                        for(let gr of Object.keys(this.$get("task/groups"))) rew['gr_'+gr] = rew.groups[gr] ? rew.groups[gr] : 0;

                    } else {

                        // Store params
                        rew.member_id = this.templateMode ? null : this.member_id;
                        rew.family_id = this.templateMode ? null : this.family.id;
                        rew.group = this.group;
                        rew.gender = ['male', 'female']
                        rew.ages = []
                        rew.is_auto_addition = false

                        // Add groups values
                        for(let gr of Object.keys(this.$get("task/groups"))) rew['gr_'+gr] = 0;

                    }

                    // Load reward
                    return rew;

                },

                // fields list
                fields() {

                    // Fields list
                    let fields = {
                        title: {
                            type: "string",
                            title: this.$t("ttlRewardTitle"),
                            validation: ["required"]
                        },
                        icon: {
                            title: this.$t("ttlSelectTaskIcon"),
                            type: 'base_icon',
                            icons: this.$get("misc/var", 'baseIcons').rewards,
                            validation: ["required"]
                        },
                        order: {
                            type: 'string',
                            title: this.$t("ttlOrderField"),
                            fieldType: 'number'
                        },
                        ages: {
                            title: 'Ages',
                            type: 'checkbox',
                            options: [
                                {
                                    title: '4-6',
                                    val: Object.keys(window.app.$yearsTable)[0]
                                },
                                {
                                    title: '7-10',
                                    val: Object.keys(window.app.$yearsTable)[1]
                                },
                                {
                                    title: '11-13',
                                    val: Object.keys(window.app.$yearsTable)[2]
                                },
                                {
                                    title: '14-17',
                                    val: Object.keys(window.app.$yearsTable)[3]
                                },
                                {
                                    title: '18+',
                                    val: Object.keys(window.app.$yearsTable)[4]
                                },
                            ],
                            validation: ["required"]
                        },
                        gender: {
                            title: 'Gender',
                            type: 'checkbox',
                            options: [
                                {
                                    title: 'Female',
                                    val: 'female'
                                },
                                {
                                    title: 'Male',
                                    val: 'male'
                                }
                            ],
                            validation: ["required"]
                        },
                        is_auto_addition: {
                            title: 'Automatic addition',
                            type: 'switch-bool',
                        }
                    };

                    // Check for template edit mode
                    /*if(this.templateMode)
                    {
                        // Add groups
                        for(let name of Object.keys(this.$get("task/groups")))
                            fields['gr_'+name] = {type: "range", title: this.$t('ttl'+name), min: 0, max: 1000, step: 1};
                    }
                    else {
                        // Add groups
                        for(let name of this.$get("task/memberGroups", this.member_id))
                            fields[name] = {type: "range", title: this.$t('ttl'+name.substr(3)), min: 0, max: 1000, step: 1};
                    }*/

                    // Add groups
                    for (let name of Object.keys(this.$get("task/groups"))) {
                        fields['gr_'+name] = {
                            type: "numeric",
                            // title: this.$t('ttl'+name),
                            placeholder: this.$t('ttl' + name),
                            unit: this.$t('ttlUnitPoints'),
                            min: 0,
                            max: 1000,
                            step: 1
                        };
                    }

                    // Return fields
                    return fields;
                },

                // Buttons list
                buttons() {

                    // Buttons list
                    let btns = {close: true};

                    // Save button
                    btns['save'] = {
                        title: this.isUpdateMode ? this.$t('btSave') : this.$t('btAdd'),
                        class: "md-primary md-raised",
                        type: "submit"
                    }

                    // Return list
                    return btns;
                }

            },

        // Methods list
        methods:
            {

                /**
                 * Add reward
                 */
                save(values) {

                    // Set update flag
                    let isUpdate = this.isUpdateMode;

                    // Unpack groups
                    let groups = {};
                    Object.keys(values).filter( (k) => (k.indexOf("gr_") != -1) ).map((k) => {
                        // Store group
                        groups[k.substr(3)] = values[k];
                    } );

                    // Unpack groups
                    values.groups = groups;

                    // Store family id into values
                    values.family_id = this.templateMode ? null : this.family.id;

                    if (values.order) {
                        values.order = parseInt(values.order);
                    } else {
                        values.order = null;
                    }

                    // Check for update mode
                    if (!isUpdate) {

                        // Set group
                        values.group = this.group;

                        // Add reward to the family
                        this.$dispatch("reward/create", values).then(() => {
                            this.$dispatch('translation/set', {
                                id: values.title,
                                language: this.$get('translation/currentLanguage'),
                                value: values.title,
                                group: 'common'
                            }).then(() => {
                                this.$emit("close");
                            });
                        });
                    } else {
                        // Add reward to the family
                        this.$dispatch("reward/update", this.$api.mergeObjects(values, {id: this.id})).then(() => {
                            this.$emit("close");
                        });
                    }
                }
            }
    }
</script>

<style lang="scss">
.md-modal{
  padding: 10px;
    max-height: 800px;
    overflow: scroll;
    .ap-form {
        .checkboxes {
            display: flex;
            flex-direction: row;
        }
    }
}
@media (max-width: 415px) {
  .reward__title{
      font-size: 22px;
      margin: 10px 0 10px 0;
  }
}
</style>
